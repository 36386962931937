<template>
    <div class="settings">
        <van-nav-bar class="bar" :border="false" :title="$t('user.transaction_records')" left-arrow fixed placeholder @click-left="onClickLeft" />
        <h3 class="pc-title">{{ $t('user.transaction_records') }}</h3>
        <van-list v-model="loading" :finished="finished" :loading-text="$t('user.loading')" :finished-text="$t('user.no_more')" @load="_betRecord">
            <div v-for="(item, index) in list" :key="item.id" class="record-card" :style="{'margin-bottom': index + 1 >= list.length ? 0 : '12px'}">
                <p><span>{{ $t('records.phase') }}：</span>{{ item.issue_num }}</p>
                <p><span>{{ $t('records.push_red') }}/{{ $t('records.odd') }}：</span>{{ item.red_amount }}/{{ item.quiz.red_odd }}</p>
                <p><span>{{ $t('records.push_blue') }}/{{ $t('records.odd') }}：</span>{{ item.blue_amount }}/{{ item.quiz.blue_odd }}</p>
                <p><span>{{ $t('user.state') }}：</span>{{ item.status == 1 ? $t('records.opened') : $t('records.not_opened') }}</p>
                <p v-if="item.status == 1"><span>{{ $t('records.result') }}：</span>{{ item.result == 'R' ? $t('records.red_victory') : $t('records.blue_victory') }}</p>
                <p v-if="item.status == 1"><span>{{ $t('records.red_profit') }}：</span>{{ item.red_win_amount ? item.red_win_amount : item.red_amount > 0 ? `-${item.red_amount}` : 0 }}</p>
                <p v-if="item.status == 1"><span>{{ $t('records.blue_profit') }}：</span>{{ item.blue_win_amount ? item.blue_win_amount : item.blue_amount > 0 ? `-${item.blue_amount}` : 0 }}</p>
            </div>
        </van-list>
    </div>
</template>

<script>
import { betRecord } from '@/api'

export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            page: 1,
            limit: 10,
        };
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        _betRecord() {
            betRecord({
                page: this.page,
                limit: this.limit
            }).then(({ data }) => {
                this.page++
                this.list = this.list.concat(data.data)
                this.loading = false
                this.finished = data.current_page == data.last_page
            })
        },
    }
};
</script>

<style lang="less" scoped>
.record-card {
    background-color: #fff;
    padding: 12px;
    > p {
        margin-bottom: 12px;
        span {
            color: #a1a1a1;
            &:first-child {
                color: #909399;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.bar /deep/.van-nav-bar {
    background: var(--bg-color);
}
.bar /deep/ .van-nav-bar__left .van-icon {
    color: var(--font-color);
}
.bar /deep/ .van-nav-bar__title {
    color: var(--font-color);
}
.settings {
    min-height: 100vh;
    background: url(../../../assets/login_bg.png) left top no-repeat
        var(--bg-color);
    background-size: 100%;
}
.pc-title {
    display: none;
}
@media only screen and (min-width: 750px) {
    .settings {
        background: none;
        padding: 42px;
        position: relative;
        box-sizing: border-box;
        .bar {
            display: none;
        }
        .pc-title {
            display: block;
            font-weight: 800;
            font-size: 28px;
            color: var(--font-color);
        }
        /deep/.van-list__error-text,
        /deep/.van-list__finished-text,
        /deep/.van-list__loading {
            color: #969799;
            font-size: 28px;
            line-height: 45px;
            text-align: center;
        }
        /deep/.van-list__finished-text {
            margin: 50px auto;
        }
        /deep/ .van-list {
            margin: 20px 0;
        }
        .record-card {
            font-size: 24px;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0px 4px 13px 0px rgba(80, 65, 57, 0.2);
            border-radius: 20px 20px 20px 20px;
            margin-bottom: 20px !important;
            > p {
                margin-bottom: 12px;
                span {
                    color: #909399;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
